import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import Layout from '../components/Layout'
import TopBanner from '../components/TopBanner'
import ClientIcons from '../components/ClientIcons'

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding-top: 10vh;
  align-items: center;
  cursor: default;
  position: relative;
  background: black;
  z-index: 1;
`

const skew = keyframes`
  1% { transform: skew(0deg);}
  1.25% { transform: skew(15deg);}
  1.5% { transform: skew(0deg);}
  19.75% { transform: skew(0deg); opacity: 1;}
  20% { transform: skew(100deg); opacity: 0.75;}
  20.25% { transform: skew(0deg); opacity: 1;}
`

const StyledEmailLink = styled.a`
  animation: ${skew} ${Math.random() * 4}s infinite;
  text-decoration: none;
  color: #ffcccb;
  letter-spacing: 1vw;
  font-size: calc(3vw + 12px);
  line-height: calc(3.5vw + 12px);
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding-left: 4vw;
  margin-bottom: 5vh;
  :visited {
    text-decoration: none;
    color: #ffcccb;
  }
`

const StyledSpan = styled.span`
  font-size: calc(2vw + 12px);
  opacity: 0.7;
  display: block;
`

const ScrollButton = styled.button`
  box-sizing: border-box;
  color: #affdd3;
  // width: 250px;
  transition: color 0.25s ease;
  background: transparent;
  border: none;
  font-weight: 300px;
  font-size: calc(0.5vw + 14px);
  z-index: 100;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  font-weight: 300;
  &:focus {
    outline: 1px solid #ffcccb;
  }
  &:hover {
    color: #ffcccb;
  }
`

const onClick = () => {
  const clients = document.getElementById('clients')
  clients && clients.scrollIntoView({
    behavior: 'smooth',
  })
}

export const BookingPageTemplate = ({ email, title }) => {
  return (
    <Layout hasPadding autoHeight style={{ width: 'auto' }}>
      <TopBanner title="booking" />
      <StyledSection>
        <StyledEmailLink href={`mailto:${email}`}>
          <StyledSpan>FOR BOOKINGS:</StyledSpan>
          {email}
        </StyledEmailLink>
        <ScrollButton onClick={onClick}>Clients Include</ScrollButton>
        <ClientIcons id="clients" />
      </StyledSection>
    </Layout>
  )
}

BookingPageTemplate.propTypes = {
  email: PropTypes.string,
}

const BookingPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <BookingPageTemplate
      title={post.frontmatter.title}
      email={post.frontmatter.email}
    />
  )
}

BookingPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BookingPage

export const bookingPageQuery = graphql`
  query BookingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        email
      }
    }
  }
`
